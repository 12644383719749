import React from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import { Title, Subtitle } from "../elements/Titles";
import { Link, graphql } from "gatsby";
import PostWrapper from "../views/blog/PostWrapper";
import { colors } from "../../tailwind";
import { withPrefix } from "gatsby";
//import serverlessarchitecture from "../images/serverless_nodejs_graphql/serverlessarchitecture.png";
// Components
import Layout from "../components/Layout";
import Footer from "../components/Footer";

const TextWrapper = styled.div`
  ${tw`tracking-normal antialiased subpixel-antialiased font-sans max-w-full text-left px-2`}
`;

const PrivacyPolicy = props => {
  return (
    <>
      <Layout location={props.location} />
      <PostWrapper>
        <Title>Welcome to our Privacy Policy</Title>
        <Subtitle>Your privacy is really important to us !</Subtitle>
        <TextWrapper>
          <p>
            It is our policy to respect your privacy regarding any
            information we may collect while operating our website. This Privacy
            Policy applies to : &nbsp;
            <a href="https://www.dasithsblog.com">
              https://www.dasithsblog.com
            </a> &nbsp;
            (hereinafter, "us", "we" , "this website", or "https://www.dasithsblog.com"). We
            respect your privacy and are committed to protecting personally
            identifiable information you may provide us through the Website. We
            have adopted this privacy policy ("Privacy Policy") to explain what
            information may be collected on our Website, how we use this
            information, and under what circumstances we may disclose the
            information to third parties. This Privacy Policy applies only to
            information we collect through the Website and does not apply to our
            collection of information from other sources.
          </p>
          <p>
            This Privacy Policy, together with the Terms and conditions posted
            on our Website, set forth the general rules and policies governing
            your use of our Website. Depending on your activities when visiting
            our Website, you may be required to agree to additional terms and
            conditions.
          </p>
          <Subtitle>Website Visitors</Subtitle>
          <p>
            Like most website operators, This website collects
            non-personally-identifying information of the sort that web browsers
            and servers typically make available, such as the browser type,
            language preference, referring site, and the date and time of each
            visitor request. This website’s purpose in collecting
            non-personally identifying information is to better understand how
            This website’s visitors use its website. From time to time,
            This website may release non-personally-identifying information in
            the aggregate, e.g., by publishing a report on trends in the usage
            of its website.
          </p>
          <p>
            This website also collects potentially personally-identifying
            information like Internet Protocol (IP) addresses for logged in
            users and for users leaving comments on https://www.dasithsblog.com
            blog posts. This website only discloses logged in user and
            commenter IP addresses under the same circumstances that it uses and
            discloses personally-identifying information as described below.
          </p>
          <Subtitle>Security</Subtitle>
          <p>
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
          </p>
          <Subtitle>Advertisements</Subtitle>
          <p>
            Ads appearing on our website may be delivered to users by
            advertising partners, who may set cookies. These cookies allow the
            ad server to recognize your computer each time they send you an
            online advertisement to compile information about you or others who
            use your computer. This information allows ad networks to, among
            other things, deliver targeted advertisements that they believe will
            be of most interest to you. This Privacy Policy covers the use of
            cookies by This website and does not cover the use of cookies by
            any advertisers.
          </p>
          <Subtitle>Links To External Sites</Subtitle>
          <p>
            Our Service may contain links to external sites that are not
            operated by us. If you click on a third party link, you will be
            directed to that third party's site. We strongly advise you to
            review the Privacy Policy and terms and conditions of every site you
            visit.
          </p>
          <p>
            We have no control over, and assume no responsibility for the
            content, privacy policies or practices of any third party sites,
            products or services.
          </p>
          <Subtitle>Aggregated Statistics</Subtitle>
          <p>
            This website may collect statistics about the behavior of visitors
            to its website. This website may display this information publicly
            or provide it to others. However, This website does not disclose
            your personally-identifying information.
          </p>
          <Subtitle>Cookies</Subtitle>
          <p>
            To enrich and perfect your online experience, This website uses
            "Cookies", similar technologies and services provided by others to
            display personalized content, appropriate advertising and store your
            preferences on your computer.
          </p>
          <p>
            A cookie is a string of information that a website stores on a
            visitor’s computer, and that the visitor’s browser provides to the
            website each time the visitor returns. This website uses cookies to
            help This website identify and track visitors, their usage of
            https://www.dasithsblog.com, and their website access preferences.
            This website visitors who do not wish to have cookies placed on
            their computers should set their browsers to refuse cookies before
            using This website’s websites, with the drawback that certain
            features of This website’s websites may not function properly
            without the aid of cookies.
          </p>
          <p>
            By continuing to navigate our website without changing your cookie
            settings, you hereby acknowledge and agree to This website's use of
            cookies.
          </p>
      
        <Subtitle>Privacy Policy Changes</Subtitle>
        <p>
          Although most changes are likely to be minor, This website may change
          its Privacy Policy from time to time, and in This website’s sole
          discretion. This website encourages visitors to frequently check this
          page for any changes to its Privacy Policy. Your continued use of this
          site after any change in this Privacy Policy will constitute your
          acceptance of such change.
        </p>

        <p>
          If you have any questions about this Privacy Policy, please contact us
          via <a href="mailto:">email</a> or{" "}
          <a href="tel:094766887772">phone</a>.
        </p>
        <br/>
        <br/>
        </TextWrapper>
      </PostWrapper>

      <Footer position="relative" />
    </>
  );
};
export default PrivacyPolicy;
